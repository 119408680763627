import React from 'react';
import { Link } from 'gatsby'
import JobList from "../../components/Jobs/VerticalJobsByCompany"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { trim, split, last, isEmpty, head, has, isNil } from "lodash"
import { getCompanyDetail } from "../../actions/company"
import ContentDetailLoader from "../../components/Loader/ContentDetailLoader"
import moment from "moment"

class CompanyDetail extends React.Component {

    constructor(props) {
      super(props)
      this.state = {
        id:null,
        loaded:false,
        loading:false,
        item:null,
        error:''
      }
    }

    componentDidMount() {
      const { location } = this.props
      const pathArr = split(trim(location.pathname, '/'), '/')
      const pathEnd = last(pathArr)
      const detailId = split(pathEnd, '_')
      const compId = detailId.length > 1 ? head(detailId) : null

      this.setState({
        id:compId,
        loading:true
      }, ()=> {
        getCompanyDetail({
          id:compId
        }).then(res=>{
          this.setState({
            loaded:true,
            loading:false,
            item:res.data
          })
        }).catch(err=>{
          if( isNil(err.response) ) {
            this.setState({
              loading:false,
              loaded:true,
              error:'Error Internet Connection'
            })
          } else {
            if( err.response.status == 200 ) {
              this.setState({
                loading:false,
                loaded:true,
              })
            } else {
             
              this.setState({
                loaded:true,
                loading:false,
                error:has(err.response, 'data.messages') ? err.response.data.messages : 'Something went wrong from server connection'
              })
            }
          }
          
        })
      })
      
    }

    render() {
      const { location } = this.props
      const { item } = this.state
      
      if( this.state.loaded && this.state.error != '' ) {
        return <Layout>
            <SEO title="Company Detail" />
            <h1>Not found</h1>
            <h5>{this.state.error}</h5>
        </Layout>
      }

      if( !item && this.state.loading ) {
        return <Layout>
            <SEO title="Company Detail" />
            <ContentDetailLoader />
        </Layout>
      }

      if( item ) {
        return (
        <Layout>
          <SEO title="Company Detail" />
          <div className="container" style={{ marginBottom: `1.45rem` }}>
              <nav aria-label="breadcrumb mb-3">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                  <li className="breadcrumb-item"><Link to={'/company'}>Company</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">{item.employers_company_name}</li>
                </ol>
              </nav>

              <div className="company-detail row">
                  <div className="comp-logo col-12 col-lg-2">
                    <div className="shadow-sm rounded border d-flex align-items-center" style={{
                      width:160,
                      height:160
                    }}>
                      {item && <img className="align-middle mb-0" src={item.employers_logo} alt={item.employers_company_name} style={{maxWidth:158}}/>}
                    </div>
                  </div>
                  <div className="comp-sum col-12 col-lg-10">
                    <div className="ml-3">
                      <h1 className="display-4 mb-2" style={{fontSize:`2.2rem`}}>{item.employers_company_name}</h1>
                      {(item.businness_type || item.company_type) && <div className="mb-2">
                        <h4>
                          {item.company_type && <span className="badge badge-pill badge-danger">{item.company_type.company_type}</span>} &nbsp;
                          {item.businness_type && <span className="badge badge-pill badge-light" style={{background:'#ccc'}}>{item.businness_type.business_type}</span>} 
                        </h4>
                      </div>}

                      <div className="mb-2">
                        {item.employers_address} <br/>
                        {item.employers_zipcode && <span>{item.employers_zipcode}</span>} <br/>
                        {item.employers_contact_website && <a href={item.employers_contact_website}>{item.employers_contact_website}</a>}
                      </div>
                      
                    </div>
                      
                  </div>
              </div>

              <div className="row mt-5"> 
                <div className="col-12">
                  <div className="card bg-light mb-3">
                    <div className="card-body">
                      <div className="row">
                          <div className="col-12">
                            <div className="card-text">
                              {item.employers_description.split('\n').map((item, key) => {
                                  return <span key={key}>
                                    <span dangerouslySetInnerHTML={{
                                      __html: item
                                    }}/>
                                  <br/></span>
                              })}
                              <br/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>             
              </div>
              
              { ! isEmpty(item.opening_jobs) && <div className="row mt-5">
                    <div className="col-12">
                        <h1 className="display-4 mb-2" style={{fontSize:`2.2rem`}}>Opening Jobs</h1>
                        <JobList height={230} items={item.opening_jobs} />
                    </div>
              </div>}

          </div>
        </Layout>
        )
      }

      return null
    }
  }

export default CompanyDetail


