
import React from "react"
import { kebabCase } from "lodash"
import { Link } from "gatsby"
import { displayHumanDate } from "../../utils/helpers"
import moment from "moment"

const VerticalJobsByCompany = (props) => {
    const { items, title='Latest Jobs', height=160 } = props
    return (<div style={{ margin: '2rem auto' }}>
      <ul className="row justify-content-center" style={{ padding: 0 }}>
        {items.map(item => (
          <li
            key={item.job_id}
            className={"col-12 col-lg-4 mb-5"}
            style={{
              height:height,
              float:"left",
              listStyle: 'none',
            }}
          >
              <div className="shadow card mb-3" style={{height:height, color:'#333'}}>
                <div className="row no-gutters">
                    <div className="col-md-12">
                        <div className="card-body bg-light" style={{height:height}}>
                            <h4 className="card-title mb-2" style={{
                              fontSize:(item.job_title.length > 35 ? 20 : '1.40rem')
                            }}>{item.job_title} {item.is_closed && <span className="badge badge-pill badge-secondary" style={{fontSize:'0.86rem'}}>Closed</span>} </h4>
                            <p className="mb-1 text-danger" style={{fontSize:`0.9rem`}}>{item.peropinsi ? item.peropinsi.lokasi_nama : ''}</p>

                            <p className="mb-2" style={{fontSize:`0.9rem`}}>
                                Deadline date <strong>{moment(item.job_finish_date).format("DD MMM YYYY")}</strong>
                            </p>

                            <h5 className="mb-2">
                              {item.job_type_id && <span className="badge badge-pill badge-warning">{item.job_type_obj.job_type}</span>}
                              {item.industry_type_label && <span className="badge badge-pill badge-danger">{item.industry_type_label}</span>}
                            </h5>
                        </div>

                        <div className="card-footer" style={{position:"absolute", bottom:0, width:"100%"}}>
                            <div className="row">
                                <div className="col-8">
                                    <span className="text-muted" style={{fontSize:14}}>Published on {displayHumanDate(item.job_start_date)}</span>
                                </div>
                                <div className="col text-right">
                                    <Link style={{fontSize:12}} className="btn btn-md btn-outline-primary" to={`/job/detail/${item.job_id}_${kebabCase(item.job_title)}`} state={{ id: item.job_id }}>
                                        Detail
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
          </li>
        ))}
      </ul>
    </div>)
};
  

export default VerticalJobsByCompany