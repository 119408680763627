import React from 'react';
import CompList from "./list"
import CompDetail from "./detail"
import { has } from "lodash"

const CompanyIndex = ({location, pageContext}) => {
    if( has(pageContext, 'isDetail') && pageContext.isDetail ) {
        return (<CompDetail location={location} />)
    } else {
        return (<CompList location={location} />)
    }   
}

export default CompanyIndex